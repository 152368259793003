import React, { useState, useEffect } from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import ButtonAppBar from "./nav-bar"
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import cardData from "./cardItems";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight:"100px",
    
    
  },

  flexOveride:{
  	flexBias:"auto !important"
  },
  	portfolio:{
  		textAlign:"left",
  		marginTop:"10px"
  	},
  	portfolioTags:{
  		margin:"auto",
  		color:"#282c34"
  	},
  	portfolioSub:{
  		margin:"auto",
  		color:"#282c34",
  		fontWeight:200
  	},
	cardRoot: {
    minHeight: 100,
    borderTop: "2px solid #95a5a6"
     
  },
  media: {
    height: 400,
    backgroundPostion:"top"
  },
  cardButtons:{
  	borderBottom: "5px solid #bdc3c7",
    backgroundColor: "#ecf0f1",
    boxShadow: "none"
  },
  cardHeader:{
  	textAlign: "left",
    fontWeight: "900",
  },
  cardRemove:{
  	boxShadow: "none",
    borderRadius: 0

  },
  cardActionGrid:{
  	alignItems:"flex-end"
  },
  cardAction:{
  	textAlign: "left",
    fontWeight: "900",
    marginLeft:10,
    marginBotton:10
  },
  cardDesc:{
  	textAlign:"left"
  }

}));

const cardStyles = makeStyles({
  
});



	export function Header(){
	  return(
	  	<div>
	  		<ButtonAppBar />
	  	</div>
	  );
	}

	export function Main(){
		const classes = useStyles();
		 const preventDefault = (event) => event.preventDefault();

		  return(
		    <Container maxWidth="md" className="App-container">
		    	
		    	<div className={classes.root}>
			      <Grid container spacing={3} justifyContent="center">
			        <Grid item xs={12} justifyContent="flex-start" className={classes.portfolio}>
		    			<h3 className={classes.portfolioTags}>Portfolio</h3>
		    			<p className={classes.portfolioSub}>a sample of my work</p>
			        </Grid>
			        	{cardData.map(item=>(
						<Grid item xs={12} sm={6} md={6} className={classes.flexOveride}>
						    <Card className={classes.cardRemove}>
								
									<CardMedia className={classes.media}
										image={item.image}
										title="Contemplative Reptile"
										     /> 
									<CardContent className={classes.cardRoot}>
					          			<Typography gutterBottom variant="h5" component="h2" className={classes.cardHeader}>
					            		{item.name}
					          			</Typography>
							        	<Typography variant="body2" color="textSecondary" component="p" className={classes.cardDesc}>
							            Role: {item.role}<br />
							            Description: {item.description}
							            
					          			</Typography>
					          			
					        		</CardContent>
					      		
						      	<CardActions className={classes.cardButtons}>
					
						        	<Button size="small" color="primary">
						        	<Link href={item.url} target="_blank" rel="noopener">
    									{item.linkTitle}
  									</Link>
  									</Button>
						      	</CardActions>
					    	</Card>
					    </Grid>
						   ))}
			      </Grid>
			      </div>
		    </Container>
		  );
		}

	export function AboutMain(){
		const classes = useStyles();
		 const preventDefault = (event) => event.preventDefault();

		  return(
		    <Container maxWidth="md" className="App-container">
		    	
		    	<div className={classes.root}>
			      <Grid container spacing={3} justifyContent="center">
			        <Grid item xs={12} justifyContent="flex-start" className={classes.portfolio}>
		    			<h3 className={classes.portfolioTags}>Tyler Lee Weldon,PhD</h3>
		    			<p className={classes.portfolioSub}>a little about me</p>
			        </Grid>
			        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flexOveride}>
						    <Card className={classes.cardRemove}>
						     <Grid container spacing={3} className={classes.cardActionGrid}>
						    <Grid item xs={12} sm={12} md={6} lg={6}>
									<Typography variant="h5" justifyContent="center" component="h2" className={classes.cardAction}>
					           Program Manager
					          </Typography>
					          <Typography variant="h5" justifyContent="center" component="h2" className={classes.cardAction}>
					           Instructional Designer
					          </Typography>
					          <Typography variant="h5" justifyContent="center" component="h2" className={classes.cardAction}>
					           Data Analyst 
					          </Typography>
					          <Typography variant="h5" justifyContent="center" component="h2" className={classes.cardAction}>
					           Higher Education Instructor
					          </Typography>
								 </Grid>
									<Grid item xs={12} sm={12} md={6} lg={6}>
									<CardMedia className={classes.media}
										image="img/profile.jpg"
										title="Contemplative Reptile"
										     />
										</Grid>
										</Grid>
									<CardContent className={classes.cardRoot}>
					          			<Typography gutterBottom variant="h5" component="h2" className={classes.cardHeader}>
					            		 
					          			</Typography>
							        	<Typography variant="body2" color="textSecondary" component="p" className={classes.cardDesc}>
							            Program Manager with 10 years of experience in Adult Education theory and Web Development. Currently manages university-wide learning management system and academic technologies. Creates detailed analytical reports using big data and Tableau. Leads multi-level projects with key university stakeholders. Adjunct instructor for the UAB Computer Science department with a focus on web development.<br /><br />
													Previously served as an Instructional Designer and Web Application Developer creating training material and full stack web and mobile applications. Combines technology background with education and science degrees to create new and innovative approaches to curriculum and project management.
					          			</Typography>
					        		</CardContent>
					      		
						      	<CardActions className={classes.cardButtons}>
					
						        	
						      	</CardActions>
					    	</Card>
					    </Grid>
			      </Grid>
			      </div>
		    </Container>
		  );
		}

	export function ResumeMain(){
		const classes = useStyles();
		 const preventDefault = (event) => event.preventDefault();

		  return(
		    <Container maxWidth="md" className="App-container">
		    	
		    	<div className={classes.root}>
			      <Grid container spacing={3} justifyContent="center">
			        <Grid item xs={12} justifyContent="flex-start" className={classes.portfolio}>
		    			<h3 className={classes.portfolioTags}>Resume</h3>
		    			<p className={classes.portfolioSub}>experience education and more</p>
			        </Grid>
			        <Grid item xs={12} sm={12} md={12} lg={12} className={classes.flexOveride}>
						    <Card>
								
									
									<CardContent className={classes.cardRoot}>
					          			<Typography gutterBottom variant="h5" component="h2" className={classes.cardHeader}>
					            		 
					          			</Typography>
							        	<Typography variant="body2" color="textSecondary" component="p" className={classes.cardDesc}>
							            Program Manager with 10 years of experience in Adult Education theory and Web Development. Currently manages university-wide learning management system and academic technologies. Creates detailed analytical reports using big data and Tableau. Leads multi-level projects with key university stakeholders. Adjunct instructor for the UAB Computer Science department with a focus on web development.<br /><br />
													Previously served as an Instructional Designer and Web Application Developer creating training material and full stack web and mobile applications. Combines technology background with education and science degrees to create new and innovative approaches to curriculum and project management.
					          			</Typography>
					        		</CardContent>
					      		
						      	<CardActions className={classes.cardButtons}>
					
						        	
						      	</CardActions>
					    	</Card>
					    </Grid>
			      </Grid>
			      </div>
		    </Container>
		  );
		}


	export function Footer() {
	  return(
	     <footer><p>Copyright 2021</p></footer>
	  ); 
	}
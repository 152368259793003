import React from "react";

const cardData =  [

	{
		id:1,
		name:"AU Involve",
		description:"Custom wordpress site build for Auburn Univeristy Student Involvement",
		url:"http://wp.auburn.edu/involve/",
		image:"img/involve.png",
		role:"Developer",
		linkTitle:"website"
	},
	{
		id:2,
		name:"SOW Mobile App",
		description:"Full Stack Mobile Application available on Apple and Android",
		url:"https://apps.apple.com/us/app/sow-a-planting-companion/id840347996",
		image:"img/sowapp.png",
		role:"Developer",
		linkTitle:"website"
	},
	{
		id:3,
		name:"Tableau Dashboards",
		description:"LMS data project using MS SQL and Tableau",
		url:"https://www.youtube.com/watch?v=vHiFMxpcDvw",
		image:"img/canvasdata.jpeg",
		role:"Speaker",
		linkTitle:"YouTube"
	},
	{
		id:4,
		name:"Training and Support",
		description:"Canvas Live event about UAB eLearning training and support efforts",
		url:"https://www.youtube.com/watch?v=xto7lbqm4Ro",
		image:"img/canvaslive.jpeg",
		role:"Speaker",
		linkTitle:"YouTube"
	},
	{
		id:5,
		name:"Software Training",
		description:"Software training document created for Auburn University",
		url:"docs/softwaretraining.pdf",
		image:"img/",
		role:"Curriculum Developer",
		linkTitle:"Document"
	},
	{
		id:6,
		name:"Mobile Video Training",
		description:"Mobile training Curriculum created for Alabama Extension",
		url:"docs/mobiletraining.pdf",
		image:"img/",
		role:"Curriculum Developer",
		linkTitle:"Document"
	}

	]



export default cardData;
import React, { useState, useEffect } from "react";
import { Header, Main, AboutMain, ResumeMain, Footer} from "./page-components";


export function Home(){

	return(
		<div className="App">
		<Header />
		<Main />
		</div>
	)
	
}

export function About(){
	return(
		<div>
		<Header />
		<AboutMain />
		</div>
	);
}

export function Resume(){
	return(
		<div>
		<Header />
		<ResumeMain />
		</div>
	);
}

export function Contact(){
	return(
		<Header />
	);
}
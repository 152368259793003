import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { BrowserRouter as Router, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   
  },
  theme:{
     background:'#e67e22',
     boxShadow: 'none',
     borderBottom: "2px solid #d35400"
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign:"right",
    color:"#fff",
    fontFamily: "sans-serif",
    fontWeight: "900",
    fontSize: "x-large",
    marginRight:"5px",
    textDecoration:"none"

  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.theme}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} component={Link} to="/">
            TLW
          </Typography>

          <Button color="inherit" component={Link} to="/about">About</Button>
          <Button color="inherit" component={Link} to="/resume">Resume</Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
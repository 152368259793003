import './App.css';
import { BrowserRouter,Route, Switch} from "react-router-dom";
import{ Home, About, Resume, Contact } from "./page";



function App() {
  return (
   
    
    <Switch>
      
      <Route path="/about"> <About /> </Route>
      <Route path="/resume"> <Resume /> </Route>
      <Route path="/contact"> <Contact /> </Route>
      <Route path="/"><Home /> </Route>
    </Switch>
   
   
  );
}

export default App;